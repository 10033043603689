import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Prodcuts from "../components/products"
import Footer from "../components/footer"
import About from "../components/about"
import Hero from "../components/hero"
import Contact from "../components/contact"
import News from "../components/news"

export default class ContactPage extends React.Component {

  render() {

    return (

      <Layout>

        <Seo title="SPCO" lang="en" />

        <Contact />

        <Footer />

      </Layout>

    )

  }

}