import * as React from "react"


const Contact = () => (

    <section id="contact" className="wow animate__fadeIn">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-xl-6 col-lg-7 col-md-9 col-sm-10 margin-eight-bottom md-margin-40px-bottom sm-margin-30px-bottom text-center last-paragraph-no-margin">
                    <h5 className="alt-font font-weight-700 text-extra-dark-gray text-uppercase">CONTACT US</h5>
                    <p>info [at] spco.om</p>
                </div>
            </div>
            <form id="project-contact-form" action="#" method="post">
                <div className="row">
                    <div className="col-12">
                        <div className="form-results d-none"></div>
                    </div>
                    <div className="col-12 col-md-6">
                        <input type="text" name="name" id="name" placeholder="Name *" className="big-input required"/>
                    </div>
                    <div className="col-12 col-md-6">
                        <input type="tel" name="phone" id="phone" placeholder="Phone *" className="big-input"/>
                    </div>
                    <div className="col-12 col-md-6">
                        <input type="email" name="email" id="email" placeholder="E-mail *" className="big-input required"/>
                    </div>
                    <div className="col-12">
                        <textarea name="comment" id="comment" placeholder="Message" rows="6" className="big-textarea"></textarea>
                    </div>
                    <div className="col-12 text-center">
                        <button id="project-contact-us-button" type="submit" className="btn btn-transparent-dark-gray btn-large margin-20px-top submit">send message</button>
                    </div>
                </div>
            </form>
        </div>
    </section>

)

export default Contact